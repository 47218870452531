export function chunks(s: string, chunkSize: number): string[] {
	const result = [];
	for (let i = 0; i < s.length; i += chunkSize) {
		const chunk = s.slice(i, i + chunkSize);
		result.push(chunk);
	}
	return result;
}

export function fillWithEmpty(array: string[], target_length: number) {
	const a = [...array];
	while (a.length < target_length) {
		a.push("");
	}
	return a;
}

export function addLineNumbers(
	s: string[],
	start_num: number,
	prefix = "~"
): string[] {
	return s.map((l, i) => `${prefix}${start_num + i}${l}`);
}

export function removeRepeatingSpace(s: string) {
	return s.replace(/  +/g, " ");
}
