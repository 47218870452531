import { convert } from ".";

function download(data: string, filename: string, type: string) {
	var file = new Blob([data], { type: type });
	var a = document.createElement("a"),
		url = URL.createObjectURL(file);
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	setTimeout(function () {
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, 0);
}

async function handle() {
	const csv_input = document.getElementById("csv");
	if (!csv_input || !(csv_input instanceof HTMLInputElement)) {
		throw new Error("coudl not find the csv input");
	}
	var fileName = csv_input.value.replace("C:\\fakepath\\", "");
	const files = csv_input.files;
	if (!files) {
		throw new Error("no files in the input");
	}
	const buffer = await files[0].arrayBuffer();
	try {
		const string = new TextDecoder("windows-1250").decode(buffer);
		const result = convert(string);
		download(
			result.output,
			`raport-${result.range.date_start.getFullYear()}-${(
				result.range.date_start.getMonth() + 1
			)
				.toString()
				.padStart(2, "0")}.mt940`,
			"text"
		);
		document.querySelector(".success")?.classList.remove("hidden");
	} catch (e) {
		console.error(e);
		alert("Wystąpił błąd: " + e.message);
	}
}

document.querySelector("#submit")?.addEventListener("click", handle);
console.log("added handler to", document.querySelector("#submit"));
